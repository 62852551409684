const SPACE_DATA = {
		"u6991697":  [{space_js: "//slkiiw.renbikj.com/common/av-udd/common/eu/openjs/d-w.js", space_src: "baidujs", space_type: "inters", space_id: "u6991697"}],
		"u6991698":  [{space_js: "//slkiiw.renbikj.com/common/bwv_e_ef/ve/openjs/y.js", space_src: "baidujs", space_type: "native", space_id: "u6991698"}],
		"u6991699":  [{space_js: "//slkiiw.renbikj.com/source/c/x_w/ff/openjs/gwff.js", space_src: "baidujs", space_type: "native", space_id: "u6991699"}],
		"u6991700":  [{space_js: "//slkiiw.renbikj.com/production/k/production/f_e/resource/nnogl/openjs/l.js", space_src: "baidujs", space_type: "native", space_id: "u6991700"}],
};
export const SPACE_MAP = {
	Home_inter:  SPACE_DATA["u6991697"],
	Home_banner:  SPACE_DATA["u6991698"],
	Home_native:  SPACE_DATA["u6991699"],
	Home_native_center1:  SPACE_DATA["u6991700"],
	Detail_inter:  SPACE_DATA["u6991697"],
	Detail_banner:  SPACE_DATA["u6991698"],
	Detail_native:  SPACE_DATA["u6991699"],
	Detail_native_center1:  SPACE_DATA["u6991700"],
	Result_inter:  SPACE_DATA["u6991697"],
	Result_banner:  SPACE_DATA["u6991698"],
	Result_native:  SPACE_DATA["u6991699"],
	Result_native_center1:  SPACE_DATA["u6991700"],
}
export const ALL_SPACE_MAP = {
    H5_RBAI_VO_1 : {
		Home_inter:  SPACE_DATA["u6991697"],
		Home_banner:  SPACE_DATA["u6991698"],
		Home_native:  SPACE_DATA["u6991699"],
		Home_native_center1:  SPACE_DATA["u6991700"],
		Detail_inter:  SPACE_DATA["u6991697"],
		Detail_banner:  SPACE_DATA["u6991698"],
		Detail_native:  SPACE_DATA["u6991699"],
		Detail_native_center1:  SPACE_DATA["u6991700"],
		Result_inter:  SPACE_DATA["u6991697"],
		Result_banner:  SPACE_DATA["u6991698"],
		Result_native:  SPACE_DATA["u6991699"],
		Result_native_center1:  SPACE_DATA["u6991700"],
    },
    DEFAULT : {
		Home_inter:  SPACE_DATA["u6991697"],
		Home_banner:  SPACE_DATA["u6991698"],
		Home_native:  SPACE_DATA["u6991699"],
		Home_native_center1:  SPACE_DATA["u6991700"],
		Detail_inter:  SPACE_DATA["u6991697"],
		Detail_banner:  SPACE_DATA["u6991698"],
		Detail_native:  SPACE_DATA["u6991699"],
		Detail_native_center1:  SPACE_DATA["u6991700"],
		Result_inter:  SPACE_DATA["u6991697"],
		Result_banner:  SPACE_DATA["u6991698"],
		Result_native:  SPACE_DATA["u6991699"],
		Result_native_center1:  SPACE_DATA["u6991700"],
    },
};
